html {
  scroll-behavior: smooth;
}

.input-container {
  position: relative;
  margin: 0.25rem 0;
}

.input-field {
  display: block;
  width: 100%;
  padding: 1rem 0.625rem 0.625rem;
  font-size: 0.875rem;
  color: #1f2937;
  background-color: transparent;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  appearance: none;
}

.input-field:focus {
  outline: none;
  border-color: #127BBE;
}

.input-label {
  position: absolute;
  top: 1rem;
  left: 0.625rem;
  font-size: 0.875rem;
  color: #6b7280;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 0 0.25rem;
  transform-origin: left top;
  transform: scale(1) translateY(0);
  transition: transform 0.3s ease, color 0.3s ease, top 0.3s ease;
  pointer-events: none;
}

.input-field:focus ~ .input-label,
.input-field:not(:placeholder-shown) ~ .input-label {
  color: #127BBE;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  transform: scale(0.75) translateY(-1.5rem);
  top: 0.75rem;
}

/* text-label */
.text-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #303030;
}

.text-label::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; 
  width: 0;
  height: 2px; 
  background-color: #127BBE;  
  transition: width 0.3s ease;
}

.text-label:hover::after {
  width: 100%;
}

/* select */
.select-field {
display: block;
width: 100%;
padding: 1rem 0.625rem 0.625rem;
font-size: 0.875rem;
color: #1f2937;
background-color: transparent;
border: 1px solid #d1d5db;
border-radius: 0.25rem;
appearance: none;
}

.select-field:focus {
outline: none;
border-color: #127BBE;
}

.select-field::placeholder {
  color: #b0b0b0;
}

.select-field:focus ~ .input-label,
.select-field:not(:placeholder-shown) ~ .input-label {
color: #127BBE;
background: rgba(255, 255, 255, 0.9);
border-radius: 10px;
transform: scale(0.75) translateY(-1.5rem);
top: 0.75rem;
}

.select-field {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236b7280"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 1rem;
}

/* button-radio */
.input-container-radio {
position: relative;
margin: 1.25rem 1;
}

.input-label-radio {
position: absolute;
top: -0.5rem;
left: 0.625rem;
font-size: 0.7rem;
color: #127BBE;
background: rgba(255, 255, 255, 0.9);
border-radius: 10px;
padding: 0 0.25rem;
transform-origin: left top;
transform: scale(1) translateY(0);
transition: transform 0.3s ease, color 0.3s ease, top 0.3s ease;
pointer-events: none;
}

.select-field-radio {
margin: 0.75rem 0;
display: flex;
column-gap: 0.25rem;
width: 100%;
padding: 1rem 0.625rem 0.625rem;
font-size: 0.875rem;
color: #1f2937;
background-color: transparent;
border: 2px solid #d1d5db;
border-radius: 0.5rem;
appearance: none;
}

/* button-khaosat */
.input-label-khaosat {
position: absolute;
top: -1.25rem;
left: 0.625rem;
font-size: 0.7rem;
color: #127BBE;
background: rgba(255, 255, 255, 0.9);
border-radius: 10px;
padding: 0 0.25rem;
transform-origin: left top;
transform: scale(1) translateY(0);
transition: transform 0.3s ease, color 0.3s ease, top 0.3s ease;
pointer-events: none;
}

.select-field-khaosat {
margin-top: 2rem;
margin-bottom: 0.75rem;
display: flex;
column-gap: 0.25rem;
width: 100%;
padding: 2rem 0.625rem 0.625rem;
font-size: 0.875rem;
color: #1f2937;
background-color: transparent;
border: 2px solid #d1d5db;
border-radius: 0.5rem;
appearance: none;
}

/* input-file */
.input-container-file {
position: relative;
margin: 1.25rem 0;
}

.input-label-file {
position: absolute;
top: -0.5rem;
left: 0.625rem;
font-size: 0.7rem;
color: #127BBE;
background: rgba(255, 255, 255, 0.9);
border-radius: 10px;
padding: 0 0.25rem;
transform-origin: left top;
transform: scale(1) translateY(0);
transition: transform 0.3s ease, color 0.3s ease, top 0.3s ease;
pointer-events: none;
}

.input-field-file {
display: block;
width: 100%;
padding: 1rem 0.625rem 0.625rem;
font-size: 0.875rem;
color: #1f2937;
background-color: transparent;
border: 2px solid #d1d5db;
border-radius: 0.5rem;
appearance: none;
}

/* input[type="file"] {
position: relative;
} */

input[type="file"]::file-selector-button {
width: 136px;
color: transparent;
}

input[type="file"]::before {
position: absolute;
pointer-events: none;
top: 27px;
left: 20px;
height: 20px;
width: 20px;
content: "";
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

input[type="file"]::after {
position: absolute;
pointer-events: none;
top: 27px;
left: 44px;
color: #0C7ABF;
content: "Chọn file PDF";
}

/* ------- From Step 1 ------- */

/* file upload button */
input[type="file"]::file-selector-button {
border-radius: 8px;
padding: 0 16px;
height: 40px;
cursor: pointer;
background-color: white;
border: 1px solid gray;
box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
margin-right: 16px;
transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
background-color: #e5e7eb;
}